import { Link } from 'react-router-dom';

import Person from "../../Person";

import './styles.css';

interface Props { 
  personsList: Array<any>;
}
type props = Props

const Persons = ({ personsList }: props) => {   
  return (
    <div className='persons'>
      <div className='persons-content'>
        <div className='group'>
          {
            personsList.map((p) => {
              return (
                <Link to={p.path}>
                  <Person personName={p.personName} urlFoto={p.urlFoto} urlMapa={p.urlMapa}/>                        
                </Link>
              )
            })
          }         
        </div>       
        <p>*imagens e informacões retiradas do site Wikipédia</p>
      </div>
    </div>
  );
};

export default Persons;
