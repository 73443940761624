import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import logo from '../../../assets/images/logos/logo.png';

import './styles.css';

interface Props {  
  pageName: string, 
}
type props = Props

const Header = ({ pageName } : props) => {
  const gaEventTracker = useAnalyticsEventTracker(pageName);
  
  return (
    <header className="header">
      <div className="header-content">
        <div className="logo">
          <a href="https://www.truenumbers.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar truenumbers', 'Visitar truenumbers ')}>
            <img src={logo} alt="logo_truenumbers"/>
          </a>
        </div>
        <div className="text-block">
          <h1>ANÁLISE NUMEROLÓGICA DAS PERSONALIDADES</h1>
          <h2>Entenda o projeto de vida das pessoas mais influentes do mundo!</h2>
        </div>       
      </div>
    </header>
  );
};

export default Header;
