import React from "react";

import mobileGlobo from '../../../assets/images/globe.png';

import './styles.css';

const Globe: React.FC = () => {
  return (
    <div className='globe'>
      <div className='globe-content'>
        <div className='globe-image'>
          <img src={mobileGlobo} alt='imagem_globo'/>
        </div>
      </div>
    </div>
  );
};

export default Globe;
