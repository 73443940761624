import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Header from '../../components/pageComponents/Header';
import PersonMap from '../../components/pageComponents/PersonMap';
import Footer from '../../components/pageComponents/Footer';

import './styles.css';

interface Props { 
  person: any;
}
type props = Props

const Mapa = ({ person }: props) => {

  const pageName = person.path;
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView(pageName, pageName);
  }, []);
  
  return (
    <div id="mapa-page" className="container">           
      <Header pageName={pageName}/>
      <PersonMap pageName={pageName} person={person}/>     
      <Footer pageName={pageName} />
    </div>
  );
}

export default Mapa;
