import './styles.css';

interface Props {  
  personName: string,
  urlFoto: string,
  urlMapa: string, 
}
type props = Props

const Person = ({ personName, urlFoto, urlMapa } : props) => {
  return (
    <div className='person'>
      <div className='images'>
        <div className='foto'>
          <img src={urlFoto} alt={'foto_'+personName}/>
        </div>
        <div className='mapa'>
          <img src={urlMapa} alt='mapa'/>
        </div>              
      </div>
      <p id='name'>{personName}</p>
    </div>
  );
};

export default Person;
