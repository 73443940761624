import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';
import Mapa from './pages/Mapa';

const list = [
  {
    id: 'roberto-carlos',
    path: '/mapa/roberto-carlos',
    personName: 'ROBERTO CARLOS',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_carlos/roberto_carlos.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_carlos/mapa_roberto_carlos.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_carlos/RobertoCarlosBraga.02122023.mp3.MP3',
  },
  {
    id: 'taylor-swift',
    path: '/mapa/taylor-swift',
    personName: 'TAYLOR SWIFT',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/taylor_alison_swift/taylor_alison_swift.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/taylor_alison_swift/mapa_taylor_alison_swift.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/taylor_alison_swift/TaylorAlisonSwift.09122023.mp3.MP3',
  },
  {
    id: 'ana-hickmann',
    path: '/mapa/ana-hickmann',
    personName: 'ANA HICKMANN',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ana_hickmann/ana_hickmann.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ana_hickmann/mapa_ana_hickmann.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ana_hickmann/AnaLuciaHickmann.18122023.mp3.MP3',
  },
  {
    id: 'silvio-santos',
    path: '/mapa/silvio-santos',
    personName: 'SILVIO SANTOS',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/silvio_santos/silvio_santos.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/silvio_santos/mapa_silvio_santos.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/silvio_santos/SilvioSantos.19122023.mp3.MP3',
  },
  {
    id: 'cristiano-ronaldo',
    path: '/mapa/cristiano-ronaldo',
    personName: 'CRISTIANO RONALDO',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/cristiano_ronaldo/cristiano_ronaldo.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/cristiano_ronaldo/mapa_cristiano_ronaldo.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/cristiano_ronaldo/CristianoRonaldo.24122023.mp3.MP3',
  },
  {
    id: 'leonardo',
    path: '/mapa/leonardo',
    personName: 'LEONARDO',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/leonardo/leonardo.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/leonardo/mapa_leonardo.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/leonardo/Leonardo.02012024.mp3.MP3',
  },
  {
    id: 'anitta',
    path: '/mapa/anitta',
    personName: 'ANITTA',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/anitta/anitta.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/anitta/mapa_anitta.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/anitta/Anitta.05012024.mp3.MP3',
  },
  {
    id: 'neymar',
    path: '/mapa/neymar',
    personName: 'NEYMAR',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/neymar/neymar.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/neymar/mapa_neymar.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/neymar/Neymar.08012024.mp3.MP3',
  },
  {
    id: 'roberto-justus',
    path: '/mapa/roberto-justus',
    personName: 'ROBERTO JUSTUS',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_justus/roberto_justus.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_justus/mapa_roberto_justus.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/roberto_justus/RobertoJustus.27012024.mp3.MP3',
  },
  {
    id: 'lady-gaga',
    path: '/mapa/lady-gaga',
    personName: 'LADY GAGA',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/lady_gaga/lady_gaga.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/lady_gaga/mapa_lady_gaga.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/lady_gaga/LadyGaga.04022024.mp3.MP3',
  },
  {
    id: 'ayrton-senna',
    path: '/mapa/ayrton-senna',
    personName: 'AYRTON SENNA',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ayrton_senna/ayrton_senna.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ayrton_senna/mapa_ayrton_senna.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/ayrton_senna/AyrtonSenna.10022024.mp3.MP3',
  },
  {
    id: 'elon-musk',
    path: '/mapa/elon-musk',
    personName: 'ELON MUSK',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elon_musk/elon_musk.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elon_musk/mapa_elon_musk.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elon_musk/ElonMusk.15022024.mp3.MP3',
  },
  {
    id: 'elis-regina',
    path: '/mapa/elis-regina',
    personName: 'ELIS REGINA',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elis_regina/elis_regina.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elis_regina/mapa_elis_regina.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/elis_regina/ELIS_REGINA.mp3',
  },
  {
    id: 'chico-xavier',
    path: '/mapa/chico-xavier',
    personName: 'CHICO XAVIER',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/chico_xavier/chico_xavier.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/chico_xavier/mapa_chico_xavier.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/chico_xavier/CHICO_XAVIER.mp3',
  },
  {
    id: 'fernanda-montenegro',
    path: '/mapa/fernanda-montenegro',
    personName: 'FERNANDA MONTENEGRO',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/fernanda_montenegro/fernanda_montenegro.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/fernanda_montenegro/mapa_fernanda_montenegro.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/fernanda_montenegro/FernandaMontenegro.25032024.mp3.MP3',
  },
  {
    id: 'gisele-bundchen',
    path: '/mapa/gisele-bundchen',
    personName: 'GISELE BUNDCHEN',
    urlFoto: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/gisele_bundchen/gisele_bundchen.png',
    urlMapa: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/gisele_bundchen/mapa_gisele_bundchen.png',
    urlMP3: 'https://storage.googleapis.com/numerologia_dos_famosos_arquivos_site/gisele_bundchen/GiseleBundchen.24042024.mp3.MP3',
  },
];

interface RouteObject {
  path?: string; 
  element?: React.ReactNode | null;
}
let routes: Array<RouteObject> = list.map((p) => {
  return {
    path: p.path,
    element: <Mapa person={p} />,
  }
});
routes.push({
  path: '/',
  element: <Landing personsList={list} />
})

const publicRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: routes,
    /*children: [
      {
        path: '/',
        element: <Landing personsList={list}/>,
      }          
      {
        path: list[0].path,
        element: <Mapa person={list[0]}/>,
      },
      {
        path: list[1].path,
        element: <Mapa person={list[1]}/>,
      },
      {
        path: list[2].path,
        element: <Mapa person={list[2]}/>,
      },
      {
        path: list[3].path,
        element: <Mapa person={list[3]}/>,
      },
      {
        path: list[4].path,
        element: <Mapa person={list[4]}/>,
      },
      {
        path: list[5].path,
        element: <Mapa person={list[5]}/>,
      },
      {
        path: list[6].path,
        element: <Mapa person={list[6]}/>,
      },
      {
        path: list[7].path,
        element: <Mapa person={list[7]}/>,
      },
      {
        path: list[8].path,
        element: <Mapa person={list[8]}/>,
      },
      {
        path: list[9].path,
        element: <Mapa person={list[9]}/>,
      },
      {
        path: list[10].path,
        element: <Mapa person={list[10]}/>,
      },
    ],*/
  },  
]);

const Routes: React.FC = () => {
  return (
    <RouterProvider router={publicRoutes} />
  );
}

export default Routes;
