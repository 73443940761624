import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Header from '../../components/pageComponents/Header';
import Globe from '../../components/pageComponents/Globe';
import Persons from '../../components/pageComponents/Persons';
import Footer from '../../components/pageComponents/Footer';

import './styles.css';

interface Props { 
  personsList: Array<any>;
}
type props = Props

const Landing = ({ personsList }: props) => {

  const pageName = '/';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView(pageName, pageName);
  }, []);
  
  return (
    <div id="landing-page" className="container">      
      <Header pageName={pageName}/>
      <Globe />      
      <Persons personsList={personsList}/>
      <Footer pageName={pageName} />
    </div>
  );
}

export default Landing;
