import ReactAudioPlayer from 'react-audio-player';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import './styles.css';

interface Props {  
  pageName: string,
  person: any;
}
type props = Props

const PersonMap = ({ pageName, person } : props) => {
  const gaEventTracker = useAnalyticsEventTracker(pageName);
  
  return (
    <div className='person-map'>
      <div className='person-map-content'>
        <div className='map-block'>
          <h2>{person.personName}</h2>         
          <div className='numerology-images'>
            <div className='numerology-foto'>
              <img src={person.urlFoto} alt={'foto_'+person.personName}/>
            </div>
            <a href={person.urlMapa} target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visualizar mapa Roberto Carlos', 'Visualizar mapa Roberto Carlos')}>
              <div className='numerology-map'>
                <img src={person.urlMapa} alt='mapa'/>
              </div>
            </a>              
          </div>
          <strong>OUÇA AQUI</strong>
          <div className='player-wrapper'>
            <ReactAudioPlayer
              src={person.urlMP3}
              controls
              onPlay={() => gaEventTracker.eventTracker('Ouvir analise Roberto Carlos', 'Ouvir analise Roberto Carlos')}
              controlsList={"nodownload"}         
              />
          </div>          
           <p>*imagens e informacões retiradas do site Wikipédia</p>
        </div>       
      </div>
    </div>
  );  
};

export default PersonMap;
